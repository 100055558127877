import React from "react";
import MPSButton from "../../../components/Buttons/Buttons/MPSButton";
import MPSTextAreaWithCounters from "../../../components/Forms/MPSTextAreaWithCounters.js";

const QuestionString = ({ question, setData }) => {
  const isMultiLine = question.isMultiLine;
  let { answers = [{ valueString: null }] } = question;
  const addAnswer = () => {
    let { answers = [] } = question;
    answers = [...answers, { valueString: null }];
    setData(answers);
  };

  const setData_ = (index, value) => {
    let answers = question.answers ? [...question.answers] : [];

    answers[index] = {
      valueString: value,
    };
    setData(answers);
  };

  return (
    <div>
      {answers.map((answer, index) => {
        return isMultiLine ? ( //KUMAR added Jul 04, 2024
          <MPSTextAreaWithCounters
            key={index}
            value={answer.valueString || ""}
            onChange={(e) => setData_(index, e.target.value)}
            defaultValue={question.initialValue}
            className="form-control mb-1 survey-question-multiline"
            rows={6}
            noOfLinesInMultiline={question.noOfLinesInMultiline}
            noOfWordsInMultiline={question.noOfWordsInMultiline}
          />
        ) : (
          <input
            key={index}
            type="text"
            value={answer.valueString || ""}
            onChange={(e) => setData_(index, e.target.value)}
            defaultValue={question.initialValue}
            className="form-control mb-1"
          />
        );
      })}

      {question.isMultipleAnswersPermitted && (
        <MPSButton type="link" onClick={addAnswer} style={{ fontWeight: 500 }}>
          +{" "}
          {/* Ensure this '+' is correctly enclosed within the MPSButton tags */}
        </MPSButton>
      )}
    </div>
  );
};

export default QuestionString;
