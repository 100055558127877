import React, { PureComponent } from "react";
import UserOnboardingChallengeInvite from "../UserOnboarding/UserOnboardingChallengeInvite";
import { Button, Col, Row } from "reactstrap";
import {
  getObservationDataHolder,
  getValueFieldNameForFilter,
  getValueWhenInFilter
} from "../../Shared/Packages/UiAttributes/Utils";
import SignUpCredentialsForm from "./SignUpCredentialsForm";
import MPSBarLoader from "../../components/MPSBarLoader";
import { doE164 } from "../../utils/phone";
import { getSystemField } from "../../Shared/Utils";
import {
  getOnboardingAttributeValue,
  toCms,
  toKg
} from "../UserOnboarding/Utils";
import moment from "moment";
import Text from "../../components/Text";
import Messages from "../../Shared/Messages";
import I18nProvider from "../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

class SignupForm extends PureComponent {
  constructor(props) {
    super(props);
    this.attributesRef = {};
    // let sections  = this.props.sections||[];
    // sections = JSON.parse(JSON.stringify(sections))
    this.identificationFields = props.identificationFields || [];
    this.state = { sections: this.preProcessSections(this.props.sections) };
  }

  preProcessSections = (sections = []) => {
    sections = [...sections];
    let emailIndex = { section: -1, item: -1 };
    let phoneIndex = { section: -1, item: -1 };
    for (let i = 0; i < sections.length; i++) {
      let items = sections[i].items;
      for (let j = 0; j < items.length; j++) {
        let attribute = items[j].attribute;
        attribute = this.setFromIdentificationFields(attribute);
        let { dataField, value } = attribute;

        if (attribute.dataField === "PatientContactEmail") {
          emailIndex.section = i;
          emailIndex.item = j + 1;
        }

        if (attribute.attributeType === "DATE" && value && value.valueDate) {
          attribute.value.valueDate = moment(value.valueDate).format(
            "DD/MM/YYYY"
          );
        } else if (
          attribute.attributeType === "OBSERVATION_DATE" &&
          value &&
          value.valueObservation &&
          value.valueObservation.value &&
          value.valueObservation.value.valueDate
        ) {
          attribute.value.valueObservation.value.valueDate = moment(
            value.valueObservation.value.valueDate
          ).format("DD/MM/YYYY");
        }

        if (
          dataField === "PatientContactPhone" ||
          dataField === "PatientContactTel"
        ) {
          if (dataField === "PatientContactPhone") {
            phoneIndex.section = i;
            phoneIndex.item = j + 1;
          }
          value.valueContactPoints = value.valueContactPoints || [];
          value.valueContactPoints[0] = value.valueContactPoints[0] || {
            system: getSystemField(dataField),
            use: "WORK"
          };
          let countryCode = value.valueContactPoints[0].countryCode;
          value.valueContactPoints[0].countryCode = countryCode || "+353";
        }

        if (attribute.attributeId === "confirm-email") {
          emailIndex = { section: -1, item: -1 };
        }
        if (attribute.attributeId === "confirm-phone") {
          phoneIndex = { section: -1, item: -1 };
        }
      }
    }
    let email = null;
    let phone = null;
    if (emailIndex.section !== -1 && emailIndex.item !== -1) {
      email = sections[emailIndex.section].items[emailIndex.item - 1];
    }
    if (phoneIndex.section !== -1 && phoneIndex.item !== -1) {
      phone = sections[phoneIndex.section].items[phoneIndex.item - 1];
    }
    if (emailIndex.section !== -1 && emailIndex.item !== -1) {
      let emailItems = sections[emailIndex.section].items;
      emailItems.splice(emailIndex.item, 0, {
        attribute: {
          attributeId: "confirm-email",
          attributeType: "CONTACT_POINT",
          dataField: "PatientContactEmail",
          name: "ConfirmEmail",
          title: intl.formatMessage(Messages.confirm_email),
          value: {}
        },
        mandatory: email ? !!email.attribute.hidden : false,
        name: "ConfirmEmail",
        title: intl.formatMessage(Messages.confirm_email),
        type: "ATTRIBUTE"
      });
      if (phoneIndex.section !== -1 && phoneIndex.item !== -1) {
        phoneIndex.item++;
      }
    }
    if (phoneIndex.section !== -1 && phoneIndex.item !== -1) {
      let phoneItems = sections[phoneIndex.section].items;
      phoneItems.splice(phoneIndex.item, 0, {
        attribute: {
          attributeId: "confirm-phone",
          attributeType: "CONTACT_POINT",
          dataField: "PatientContactPhone",
          name: "ConfirmPhone",
          title: intl.formatMessage(Messages.con_ph_no),
          value: {}
        },
        mandatory: phone ? !!phone.attribute.hidden : false,
        name: "ConfirmPhone",
        title: intl.formatMessage(Messages.con_ph_no),
        type: "ATTRIBUTE"
      });
      if (emailIndex.section !== -1 && emailIndex.item !== -1) {
        emailIndex.item++;
      }
    }
    return sections;
  };

  setFromIdentificationFields = attribute => {
    let { identificationFields = [] } = this;
    if (identificationFields.length === 0) {
      return attribute;
    }

    let index = identificationFields.findIndex(
      field => field.name === attribute.name
    );
    if (index !== -1) {
      let field = identificationFields[index];
      let { type, value } = field;
      attribute.value = value;
      if (type === "DATE" && value && value.valueString) {
        attribute.value.valueDate = moment(value.valueString, "MM/DD/YYYY");
        delete attribute.value.valueString;
      } else if (
        type === "OBSERVATION_DATE" &&
        value &&
        value.valueObservation &&
        value.valueObservation.value &&
        value.valueObservation.value.valueString
      ) {
        attribute.value.valueObservation.value.valueDate = moment(
          value.valueObservation.value.valueString,
          "MM/DD/YYYY"
        );
        delete attribute.value.valueObservation.value.valueString;
      }
      attribute.hidden = true;
      identificationFields[index].processedValue = true;
    }
    return attribute;
  };

  setIdentificationFields = attributes => {
    let { identificationFields = [] } = this;
    identificationFields.forEach(field => {
      if (!field.processedValue) {
        attributes.push(field);
      }
    });
  };

  onUpdateData = (sectionIndex, name, attributes) => {
    let { sections } = this.state;
    let section = sections[sectionIndex];
    let items = section.items || [];
    items.forEach(item => {
      let attr = attributes.filter(att => att.name === item.attribute.name)[0];
      if (attr.focus) {
        attr.focus = false;
      }
      item.attribute = {
        ...attr
      };
    });
    section.items = [...items];
    this.setState({ sections: [...sections] });
  };

  validate = existingLoginUserId => {
    this.setState({ phoneEmailError: false });
    let sections = this.state.sections || [];
    sections = [...sections];

    let eitherEmailOrPhone = false;
    let pageHasEmailOrPhone = false;
    let formValid = true;
    let firstError = null;
    for (let a = 0; a < sections.length; a++) {
      let section = { ...sections[a] };
      let fields = section.items || [];
      for (let i = 0; i < fields.length; i++) {
        let field = { ...fields[i] };
        let attribute = { ...field.attribute };
        let value = getOnboardingAttributeValue(attribute);
        if (!attribute.hidden) {
          if (attribute.error) {
            formValid = false;
          } else {
            if (attribute.mandatory && !value) {
              attribute.error = intl.formatMessage(Messages.var_is_required, {
                title: attribute.title
              });
              formValid = false;
            }
          }
        }

        if (
          attribute.dataField === "PatientContactEmail" ||
          attribute.dataField === "PatientContactPhone"
        ) {
          pageHasEmailOrPhone = true;
          if (value) {
            eitherEmailOrPhone = true;
          }
        }

        if (!firstError) {
          firstError = attribute;
        }

        field.attribute = attribute;
        fields[i] = field;
      }
      section.fields = [...fields];
    }
    let phoneEmailError = pageHasEmailOrPhone && !eitherEmailOrPhone;
    this.setState({ sections: [...sections], phoneEmailError });

    let credFormValid = true;
    if (!existingLoginUserId) {
      credFormValid = this.credForm.validate();
    }

    if (!formValid && firstError) {
      window.scrollTo(0, this.attributesRef[firstError.name].offsetTop);
    }

    return formValid && !phoneEmailError && credFormValid;
  };

  isConfirmationField = attribute => {
    return (
      attribute.attributeId === "confirm-email" ||
      attribute.attributeId === "confirm-phone"
    );
  };

  submit = existingLoginUserId => {
    this.setState({ errorTerms: false });
    let valid = this.validate(existingLoginUserId);
    if (!valid) {
      return;
    }

    let sections = this.state.sections || [];
    sections = JSON.parse(JSON.stringify(sections));

    let attributes = [];
    sections.forEach(section => {
      let challengeFields = section.items || [];
      challengeFields.forEach(cf => {
        let attribute = cf.attribute;
        if (!this.isConfirmationField(attribute)) {
          let value = getOnboardingAttributeValue(attribute);
          if (value) {
            if (attribute.unit && attribute.unit === "FEET") {
              value = toCms(value, attribute.valueInches);
              attribute.value = getValueFieldNameForFilter(attribute, {
                value: {
                  [getObservationDataHolder(
                    attribute.type || attribute.attributeType
                  )]: value
                },
                code: { code: attribute.observationCode.code }
              });
            }

            if (attribute.unit && attribute.unit === "LBS") {
              value = toKg(value);
              attribute.value = getValueFieldNameForFilter(attribute, {
                value: {
                  [getObservationDataHolder(
                    attribute.type || attribute.attributeType
                  )]: value
                },
                code: { code: attribute.observationCode.code }
              });
            }

            if (attribute.attributeType === "DATE") {
              attribute.value.valueString = moment(value, "DD/MM/YYYY").format(
                "MM/DD/YYYY"
              );
              attribute.value.valueDate = null;
            } else if (attribute.attributeType === "OBSERVATION_DATE") {
              attribute.value.valueObservation.value.valueString = moment(
                value,
                "DD/MM/YYYY"
              ).format("MM/DD/YYYY");
              attribute.value.valueObservation.value.valueDate = null;
            }
            if (attribute.dataField === "PatientContactPhone") {
              let av = attribute.value;
              let phone = av.valueContactPoints[0].value;
              let cc = av.valueContactPoints[0].countryCode;
              delete av.valueContactPoints[0].countryCode;
              av.valueContactPoints[0].value = doE164(cc, phone);
              attribute.value = av;
            }
            attributes.push({ ...attribute });
          }
        }
      });
    });

    this.setIdentificationFields(attributes);
    let finalData = {
      attributes
    };
    if (!existingLoginUserId) {
      finalData.loginUserId = this.state.username;
      finalData.password = this.state.password;
    }
    this.props.submit(finalData);
  };

  setCredentialsData = (name, value) => {
    this.setState({ [name]: value });
  };

  hasExistingLoginUserId = () => {
    let existingLoginUserId = false;
    let attributes = {};
    let { sections } = this.state;
    for (let i = 0; i < sections.length; i++) {
      let items = sections[i].items;
      for (let j = 0; j < items.length; j++) {
        let attribute = items[j].attribute;
        if (attribute.validationCode === "USER_HAS_USERNAME") {
          if (attribute.dataField === "PatientContactEmail") {
            attributes["email"] = true;
          }
          if (attribute.dataField === "PatientContactPhone") {
            attributes["phone"] = true;
          }
          existingLoginUserId = true;
        }
      }
    }
    return { existingLoginUserId, loginUserIdAttrs: attributes };
  };

  setCredentialPresent = (present, loginUserId) => {
    if (present) {
      this.setState({ credentialsPresent: present, loginUserId });
    } else {
      this.setState({ credentialsPresent: false, loginUserId: null });
    }
  };

  onBlurForConfirmFields = (attribute, itemIndex, sectionIndex, field) => {
    let value = getValueWhenInFilter(attribute);
    let { sections } = this.props;
    let section = sections[sectionIndex];
    let confirmAttribute = section.items[itemIndex + 1].attribute;
    let state = {};
    if (value) {
      confirmAttribute = {
        ...confirmAttribute,
        mandatory: true,
        focus: true
      };
      state.phoneEmailError = false;
    } else {
      confirmAttribute = {
        ...confirmAttribute,
        mandatory: false,
        focus: false
      };
    }
    section.items[itemIndex + 1].attribute = confirmAttribute;
    state.sections = [...sections];
    if (field === "EMAIL") {
      state.email = value;
      //   if (this.credForm) this.credForm.state.username = value;
    }
    this.setState(state);
  };

  setAttributeRef = (name, ref) => {
    this.attributesRef[name] = ref;
  };

  render() {
    let {
      sections,
      username,
      password,
      confirmPassword,
      phoneEmailError
    } = this.state;

    let { confirmationError } = this.props;
    let {
      existingLoginUserId,
      loginUserIdAttrs
    } = this.hasExistingLoginUserId();

    return (
      <div>
        {sections.map((section, index) => {
          let challengeFields = [];
          if (section && section.items && section.items.length > 0) {
            challengeFields = section.items;
          }
          return (
            <Row className="justify-content-center mb-2" key={index}>
              <Col md={{ size: 8 }}>
                <div className="mb-3 text-center">
                  <h5 style={{ marginBottom: 0 }}>{section.title}</h5>
                  <div>{section.subTitle}</div>
                </div>

                <UserOnboardingChallengeInvite
                  sectionIndex={index}
                  facilityId={this.props.facilityId}
                  onUpdateData={(name, attributes) => {
                    this.onUpdateData(index, name, attributes);
                  }}
                  referrer="SELF_SIGNUP"
                  challengeFields={challengeFields.map(cf => {
                    if (cf.mandatory) {
                      cf.attribute.mandatory = true;
                    }
                    if (cf.readOnly) {
                      cf.attribute.readOnly = true;
                    }
                    if (cf.hidden) {
                      cf.attribute.hidden = true;
                    }
                    if (cf.helpText) {
                      cf.attribute.helpText = cf.helpText;
                    }
                    return cf.attribute;
                  })}
                  setCredentialPresent={this.setCredentialPresent}
                  onBlurForConfirmFields={this.onBlurForConfirmFields}
                  facilityCode={this.props.facilityCode}
                  setAttributeRef={this.setAttributeRef}
                  defaultTelRegionOptions={this.props.defaultTelRegionOptions}
                  featureFlags={this.props.featureFlags}
                />
                <hr style={{ borderTopColor: "1px solid #dddfe2" }} />
              </Col>
            </Row>
          );
        })}

        <Row className="justify-content-center mb-2">
          <Col md={{ size: 8 }}>
            {!existingLoginUserId && (
              <div className="mb-3 text-center">
                <h5 style={{ marginBottom: 0 }}>
                  <Text content={Messages.login_creds} />
                </h5>
                <div>
                  <Text content={Messages.set_un_pwd} />
                </div>
              </div>
            )}

            <SignUpCredentialsForm
              ref={credForm => {
                this.credForm = credForm;
              }}
              formErrors={this.state.credFormErrors}
              setCredentialsData={this.setCredentialsData}
              username={username}
              password={password}
              confirmPassword={confirmPassword}
              existingLoginUserId={existingLoginUserId}
              loginUserIdAttrs={loginUserIdAttrs}
              facilityCode={this.props.facilityCode}
              email={this.state.email}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs="12">
            {phoneEmailError && (
              <p className="text-muted text-center">
                <span style={{ color: "red" }}>
                  <Text content={Messages.email_or_phone} />
                </span>
              </p>
            )}
            {confirmationError && (
              <p className="text-muted text-center">
                <span style={{ color: "red" }}>{confirmationError}</span>
              </p>
            )}
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs="12">
            <div className="d-flex justify-content-between">
              <div>
                <Button
                  color="secondary"
                  className="px-4"
                  block
                  onClick={this.props.decrementStep}
                >
                  <Text content={Messages.back} />
                </Button>
              </div>

              <div className="d-flex align-items-center">
                {!this.props.submitting && (
                  <Button
                    size="lg"
                    color="primary"
                    className="px-4"
                    onClick={() => {
                      this.submit(existingLoginUserId);
                    }}
                  >
                    <Text content={Messages.submit} />
                  </Button>
                )}
                {this.props.submitting && (
                  <div className="d-flex" style={{ width: 50 }}>
                    <MPSBarLoader />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SignupForm;
