import React, { PureComponent } from "react";
import SignupIntro from "./SignupIntro";
import SignupForm from "./SignupForm";
import SignupConfirmation from "./SignupConfirmation";
import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import FacilityLogo from "../UserOnboarding/FacilityLogo";
import MPSBarLoader from "../../components/MPSBarLoader";
import SignupConsent from "./SignupConsent";
import SignupPreValidation from "./SignupPreValidation";
import SignupFooter from "./SignupFooter";
import StorageService from "../../services/storageService";
import SignupPatientCheck from "./SignupPatientCheck";

class SignupView extends PureComponent {
  constructor(props) {
    super(props);

    let { facilityInfo } = props;

    let step = StorageService.getFromSessionStorage("ONBOARDING_STEP");
    StorageService.removeFromSessionStorage("ONBOARDING_STEP");
    step = step ? step : facilityInfo.preValidationRequired ? 0 : 1;
    this.state = { step };

    // if (facilityInfo.preValidationRequired) {
    //   this.state = { step: 0 };
    // } else {
    //   let step = StorageService.getFromSessionStorage("ONBOARDING_STEP");
    //   StorageService.removeFromSessionStorage("ONBOARDING_STEP");
    //   this.state = { step: step || 1 };
    // }

    this.noOfSteps = 6;
  }

  decrementStep = () => {
    this.setState({ step: this.state.step - 1 });
    window.scrollTo(0, 0);
  };

  incrementStep = (noOfSteps = 1) => {
    let newStep = this.state.step + noOfSteps;
    if (this.state.step === 1) {
      StorageService.addToSessionStorage("ONBOARDING_STEP", newStep);
      window.location.reload();
    } else {
      this.setState({ step: newStep });
      window.scrollTo(0, 0);
    }
  };

  setIdentificationFields = (fields, callback) => {
    this.setState({ identificationFields: fields }, callback);
  };

  signUp = data => {
    let { attributes, loginUserId, password } = data;
    this.setState({ submitting: true, confirmationError: null, loginUserId });
    this.props
      .doSignUp(attributes, loginUserId, password)
      .then(response => {
        this.setState({
          submitting: false,
          postSubmitMessage: response.postSubmitMessage
        });
        this.incrementStep();
      })
      .catch(e => {
        this.setState({
          submitting: false,
          confirmationError:
            e.message || "Error while signing up. Please try again later."
        });
      });
  };

  render() {
    let { facilityInfo, loading } = this.props;
    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }
    if (!facilityInfo) {
      return null;
    }

    let { step, submitting, confirmationError, loginUserId } = this.state;
    let { addPatientProfile = {}, contact = [] } = facilityInfo;
    let sections = addPatientProfile.sections || [];

    let appTheme = null;
    let headerColor = null;
    if (facilityInfo && facilityInfo.appTheme) {
      appTheme = facilityInfo.appTheme;
      headerColor = appTheme.headerViewColor.replace("0x", "#");
    }

    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={10} sm={12}>
              <div
                className="ob-logos justify-content-around mt-2 p-2"
                style={
                  headerColor
                    ? {
                        backgroundColor: headerColor,
                        border: "1px solid " + headerColor,
                        borderTopRightRadius: 4,
                        borderTopLeftRadius: 4
                      }
                    : {}
                }
              >
                {this.state.step === 0 ? (
                  <div className="">
                    <img
                      alt="myPatientSpace"
                      src="/img/mps-logo.png"
                      height={24}
                    />
                  </div>
                ) : (
                  <React.Fragment>
                    {appTheme && (
                      <div className="">
                        <FacilityLogo appTheme={appTheme} height="70px" />
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={10} sm={12}>
              <CardGroup>
                <Card
                  style={
                    headerColor
                      ? { borderTopRightRadius: 0, borderTopLeftRadius: 0 }
                      : {}
                  }
                >
                  <CardBody>
                    {step === 0 && (
                      <React.Fragment>
                        {facilityInfo.preValidationRequired ? (
                          <SignupPreValidation
                            preValidationAttribute={
                              facilityInfo.preValidationAttribute
                            }
                            preValidationTitle={facilityInfo.preValidationTitle}
                            preValidationText={facilityInfo.preValidationText}
                            facilityId={facilityInfo.facilityId}
                            incrementStep={this.incrementStep}
                            facilityCode={this.props.facilityCode}
                            referrer="SELF_SIGNUP"
                          />
                        ) : null}
                      </React.Fragment>
                    )}

                    {step === 1 && (
                      <SignupIntro
                        facilityInfo={facilityInfo}
                        incrementStep={this.incrementStep}
                      />
                    )}

                    {step === 2 && (
                      <SignupConsent
                        facilityInfo={facilityInfo}
                        incrementStep={this.incrementStep}
                        decrementStep={this.decrementStep}
                      />
                    )}

                    {step === 3 && (
                      <SignupPatientCheck
                        facilityInfo={facilityInfo}
                        incrementStep={this.incrementStep}
                        decrementStep={this.decrementStep}
                        facilityCode={this.props.facilityCode}
                        setIdentificationFields={this.setIdentificationFields}
                      />
                    )}

                    {step > 3 && step < this.noOfSteps - 1 && (
                      <SignupForm
                        defaultTelRegionOptions={
                          facilityInfo.defaultTelRegionOptions
                        }
                        submitting={submitting}
                        facilityId={facilityInfo.facilityId}
                        sections={sections}
                        incrementStep={this.incrementStep}
                        decrementStep={this.decrementStep}
                        submit={this.signUp}
                        confirmationError={confirmationError}
                        facilityCode={this.props.facilityCode}
                        identificationFields={this.state.identificationFields}
                        featureFlags={facilityInfo.provisionedFeatures || []}
                      />
                    )}

                    {step === this.noOfSteps - 1 && (
                      <SignupConfirmation
                        facilityInfo={facilityInfo}
                        postSubmitMessage={this.state.postSubmitMessage}
                        fromApp={this.props.fromApp}
                        deviceType={this.props.deviceType}
                        loginUserId={loginUserId}
                      />
                    )}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>

        <Container>
          {this.state.step !== 0 && (
            <Row className="justify-content-center">
              <Col lg={6} md={10} sm={12}>
                {contact.length >= 1 && (
                  <div className="text-center my-2">
                    {contact.map((c, index) => (
                      <span>
                        {c.name ? c.name.text : ""}{" "}
                        {c.telecom && c.telecom[0] ? c.telecom[0].value : ""}{" "}
                        {index < contact.length - 1 ? " | " : ""}
                      </span>
                    ))}
                  </div>
                )}

                <div className="ob-logos mb-3 justify-content-around mt-4">
                  <div className="">
                    <img
                      alt="myPatientSpace"
                      src="/img/mps-logo.png"
                      height={24}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <div className="my-3">
            <SignupFooter />
          </div>
        </Container>
      </div>
    );
  }
}

export default SignupView;
